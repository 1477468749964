import 'slick-carousel/slick/slick-theme.css'
import 'slick-carousel/slick/slick.css'

import { useMediaQuery } from '@mantine/hooks'
import CatalogKlassCard from 'features/catalog/components/CatalogKlassCard'
import { IKlass } from 'features/klass/types'
import TrackCard from 'features/onboarding/components/TrackCard'
import Slider, { CustomArrowProps, Settings } from 'react-slick'

import styles from './KDCContentList.module.scss'

interface IKDCContentListProps {
  kdcList: IKlass[]
}

function CustomNextArrow(props: CustomArrowProps) {
  const { className, style, onClick } = props
  const isDisabled = onClick === null
  return (
    <div
      className={`${className as string} ${styles.right_arrow}`}
      style={{ cursor: isDisabled ? 'not-allowed' : 'pointer' }}
      onClick={onClick}>
      <div className="">
        <img
          src={`https://d35ai18pny966l.cloudfront.net/course/home/next_arrow${
            isDisabled ? '_disabled' : ''
          }.png`}
          alt="right arrow"
          className="h-full w-full"
        />
      </div>
    </div>
  )
}

const KDCContentList = ({ kdcList }: IKDCContentListProps) => {
  const isTablet = useMediaQuery('(max-width:1024px)')
  const settings: Settings = {
    dots: false,
    infinite: true,
    fade: false,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: false,
    speed: 500,
    touchMove: true,
    arrows: true,
    centerPadding: '40px',
    centerMode: false,
    nextArrow: <CustomNextArrow />,
  }

  return (
    <div className="home-kdc-slide relative grid w-full grid-cols-2 gap-x-4 gap-y-6 md:grid-cols-3 lg:block lg:grid-cols-4 lg:gap-x-6">
      {isTablet ? (
        kdcList.map(kdc => <CatalogKlassCard course={kdc} key={kdc.id} />)
      ) : (
        <Slider {...settings}>
          {kdcList.map(kdc => (
            <CatalogKlassCard key={kdc.id} course={kdc} />
          ))}
        </Slider>
      )}
    </div>
  )
}

export default KDCContentList
